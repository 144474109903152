.container {
  @apply flex flex-col items-center justify-center relative;
}

.banner {
  width: 100%;
  height: 30vh;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.logoContainer {
  @apply py-10 w-full flex items-center justify-center;
}