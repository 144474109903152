@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .base_input {
    @apply w-full border rounded-md p-2 !px-4;
  }

  .btn-alternative {
    background-color: #ff7b51;
    color: white;
  }

  .btn-alternative:hover {
    background-color: #f66c42;
  }

  .btn_base {
    @apply py-2 px-4 rounded-md transition-colors duration-150;
  }

  .banner_bg {
    background-color: #0061f2 !important;
    background-image: linear-gradient(1deg,
        #2e2c66 0%,
        #151348 100%) !important;
  }
}

.filter-dropdown-select .selected-option {
  bottom: 0.75rem !important;
  color: #343434;
}

.answerBtn {
  @apply text-gray-800 flex items-center border rounded-md p-2 hover:bg-gray-200 cursor-pointer hover:border-gray-200 hover:ring-2 hover:ring-offset-2 hover:ring-gray-400 focus:bg-gray-200 focus:border-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400;
}

.answerBtn.incorrect {
  @apply !bg-red-200 text-gray-800 !border-red-200 !ring-2 !ring-offset-2 !ring-red-300;
}

.answerBtn.correct {
  @apply !bg-emerald-50 !border-emerald-200 !ring-2 !ring-offset-2 !ring-emerald-300 !text-gray-700;
}

.nextQuestionBtn {
  @apply text-white bg-blue-700 flex items-center border rounded-md py-2 px-4;
  @apply hover:bg-blue-500 cursor-pointer hover:border-gray-200 hover:ring-2 hover:ring-offset-2 hover:ring-gray-400 focus:bg-blue-300 focus:border-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400;
}

.alert.correct {
  @apply !text-emerald-700 !bg-emerald-200 !border-emerald-200 !rounded-lg;
}

.alert.incorrect {
  @apply !text-gray-700 !bg-red-200 !border-red-200 !rounded-lg;
}

.alert.correct>div {
  display: none;
}

.result-answer-wrapper {
  @apply rounded-md;
}

.explanation {
  display: none !important;
}

.result-answer-wrapper {
  @apply pb-8;
}

.result-answer-wrapper .incorrect {
  @apply !bg-red-100 !ring-0 !border-red-200;
}

.result-answer-wrapper .answerBtn.btn {
  @apply hover:bg-transparent;
}

.questionWrapperBody div {
  @apply text-gray-700 text-sm font-bold;
}

.questionWrapperBody .tag-container {
  @apply font-normal;
}

.tag-container,
.questionWrapper,
.result-answer .answerBtn,
.result-answer-wrapper h3 {
  @apply cursor-default;
}

.answerBtn:disabled {
  @apply cursor-not-allowed hover:bg-transparent hover:ring-transparent hover:border-neutral-200;
}

.certificate_container iframe {
  height: 30vh;
}

@media screen and (min-width: 1200px) {

  .certificate_container iframe,
  .certificate_container {
    min-height: 80vh !important;
  }
}

.bg_congrats {
  background-image: url("/public/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.animate_to_scale {
  animation-name: scaleFromZero;
  animation-fill-mode: forwards;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes scaleFromZero {
  0% {
    transform: scale(0.2);
  }

  70% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.animate_from_scale {
  animation-name: scaleToOne;
  animation-fill-mode: forwards;
  animation-duration: 520ms;
  animation-timing-function: ease-in-out;
}

@keyframes scaleToOne {
  0% {
    transform: scale(2.2);
    opacity: 0.0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.retry_btn {
  animation: spinOnce 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes spinOnce {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}